import { SOCKET} from "../actions/actionsTypes";

const initialState = {
  rSocket: null,
};

const socketReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SOCKET:
      return {
        ...state,
        rSocket: actions.payload,
      };
   

    default:
      return state;
  }
};

export default socketReducer;