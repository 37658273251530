import React, { Component, Suspense, lazy } from "react";
import{  BrowserRouter as Router,Switch,Route } from 'react-router-dom'
import Navbar from "./Menu";
import cookie from 'react-cookies'
import loader from './assets/images/loader.gif'
import { CubeSpinner } from "react-spinners-kit"
import { Spin } from "antd";

const Rules = lazy(()=>import('./join/Rules'))
const Next = lazy(()=>import('./join/joinNext'))
const Wait =lazy (()=>import('./join/wait'))
const Notfound = lazy(() => import("./Common/Notfound"));
const Login =lazy(()=>import('./Login'))
const Forget =lazy(()=>import('./Forget'))
const Main = lazy(()=>import('./Main'))
const Update = lazy(()=>import('./Update'))
const Session =lazy(()=>import('./Session'))
const Join = lazy(()=>import('./join'))
const Game = lazy (()=>import('./Game/PartGame'))
const PartFeed =lazy(()=>import('./join/PartFeedback'))
const Thanks =lazy(()=>import('./Thankyou'))
const Over =lazy(()=>import('./Thankyou/Over'))
const Email =lazy(()=>import('./Update/EmailMessage'))
const Done  =lazy(()=>import('./Update/Done'))
const Activate =lazy(()=>import('./Update/Activate'))
const Verify =lazy(()=>import('./Update/Verify'))

  class Routes extends Component {
    state = {
      token: cookie.load("api_token"),
    };
    componentWillMount(){
      this.setState({
        token: cookie.load("api_token"),
      })
    }

    // componentDidMount(){
    //   this.setState({
    //     token: cookie.load("api_token"),
    //   })
    // }
    render() {
      const { token } = this.state;
      return (
        <Router basename={'/facilitator'}>
            {/*<Router>*/}
         {/* <Navbar/> */}

          <Suspense
            fallback={
              <div style={{ textAlign: "center",marginTop:'20%' }}>

                 <img src={loader} style={{width:'300px'}}/>
              </div>
            }
          >
            <Switch>
           
            <Route
                exact
              path={"/index.html"}
              render={(props) => <Login {...props} />}
            />
            <Route
                exact
              path={"/"}
              render={(props) => <Login {...props} />}
            />
            <Route
                exact
                path={"/Forget"}
              render={(props) => <Forget {...props} />}
            />
            <Route
            exact 
            path ={'/Thankyou/:Lang?'}
            render={(props) => <Thanks {...props} />}
            />
            <Route
             exact
             path={"/Game"}
             render ={(props)=><Game  {...props}/>}
            />
            <Route
                exact
            path={'/Session'}
            render={(props)=><Session {...props}/>}
            />
            <Route
             exact
             path={"/update/:email?/:token?"}
             render={(props) => <Update {...props} />}
            />
             <Route
             exact
             path={"/activate/:email?/:token?"}
             render={(props) => <Activate {...props} />}
            />
                <Route
             exact
             path={"/Next/:id?"}
             render={(props) => <Next {...props} />}
            />
           <Route
            exact
            path={"/Main/:Participants?"}
            render={(props) => <Main {...props} />}
            />
            <Route 
               exact
               path={'/Rules/:id?'}
               render={(props) => <Rules {...props} />}
             />
            <Route
            exact
            path={'/Join/:id?'}
            render={(props)=><Join {...props}/>}

            />
           
           <Route
            exact
            path={'/Done'}
            render={(props)=><Done {...props}/>}
           />
           <Route
            exact
            path={'/Verify'}
            render={(props)=><Verify {...props}/>}
           />
           <Route
           exact
            path={'/FeedBack/:id'}
            render={(props)=><PartFeed {...props}/>}
           />
           <Route
             exact
             path={'/wait/:id?'}
             render={(props)=><Wait {...props}/>}
           />
           <Route
           exact
           path={'/Message/:email?'}
           render={(props)=><Email {...props}/>}
           />
            <Route
             exact
             path={'/GameOver/:Lang?'}
             render={(props)=><Over {...props}/>}
           />
          <Route component={Notfound} />
            </Switch>
          </Suspense>
         
        </Router>
      );
    }
  }
  
  export default (Routes);