import { combineReducers } from 'redux';
import LangReducer from './langReducer'
import socketReducer from './socketReducer'
// import startReducer from './startReducer'
// import endReducer from './endReducer'
// import imgReducer from './imgReducer'
// import tokenReducer from './tokenReducer'
export default  combineReducers({
    socketReducer,
   LangReducer,

});