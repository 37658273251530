import { message } from "antd";

 export const Lang ={
     sessionTitle:{
         eng:"EQY Report",
         fr:"EQY Report"
     },mySessions:{
         eng:"My Sessions",
         fr:"My Sessions"
     },searchTitle:{
         eng:"Search Title",
         fr:"Search Title"
     },
     SearchTitle:{
         eng:"Search Title",
         fr:"Search Title"
     },
     SearchDate:{
         eng:"Date Range",
         fr:"Date Range"
     },
     selectFromDate:{
         eng:"Select From Date",
         fr:"Select From Date"
     },
     selectToDate:{
         eng:"Select To Date",
         fr:"Select To Date"
     },
     SearchDateTo:{
      eng:"To",
      fr:"To"
  },
     SearchBtn:{
      eng:"Search",
      fr:"Search"
     },
     Date:{
        eng:"Date",
        fr:"Date"
     },
     Title:{
        eng:"Title",
        fr:"Title"
     },client:{
        eng:"Client",
        fr:"Client"
     },
     Participant:{
        eng:"Participant",
        fr:"Participant"
     },Teams:{
        eng:"Teams",
        fr:"Teams"
     },
     NoOfParticipants:{
        eng:"Participants",
        fr:"Participants"
     },
     Team:{
        eng:"Team",
        fr:"Team"
     },
     Status:{
        eng:"Status",
        fr:"Status"
     },
     Action:{
        eng:"Action",
        fr:"Action"
     },

     //login
     loginTile:{
        eng:"Welcome to Facilitator Login",
        fr:"Welcome to Facilitator Login"
     },
     userName:{
        eng:"Enter Your Email address",
        fr:"Entrez votre email"
     },
     enterName:{
        eng:"Enter Your Name",
        fr:"Entrez votre nom"
     },
     password:{
        eng:"Password",
        fr:"Password"
     },
     login:{
        eng:"login",
        fr:"login"
     },
     forgetPassword:{
        eng:"Forgot Password?",
        fr:"Forgot Password?"
     },
     //Question Screen
     needTab:{
        eng:"Need to know",
        fr:"Besoin de savoir"
     },
     whatTab:{
        eng:"What would you do ?",
        fr:"Que feriez-vous"
     },
     dareTab:{
        eng:"I dare you",
        fr:"Je vous mets au défi"
     },
     //Participant Pannel
       partHead:{
          eng:"Join Equally Yours Session",
          fr:"Joindre Equally Yours Session"
       },
       partTitle:{
         eng:"Title:",
         fr:"Titre:"
       },
       partFac:{
          eng:"Facilitator: ",
          fr:"Facilitateur: "
       },
       joinSession:{
         eng:"join Session",
         fr:"Joindre la session"
       },
       errorMsg:{
          eng:"This session does not have room for additional participants.",
          fr:"Cette séance n'a pas de place pour d'autres participants."
       },
       rules:{
          eng:"Rules of the Game",
          fr:"Règles du jeu "
       },
       waitMsg:{
          eng:"Wait till the facilitator lets you in",
          fr:"Attendez que l'animateur vous laisse entrer"
       },proceedBtn:{
          eng:"Ok, Proceed",
          fr:"Ok, poursuivre"
       },Q1:{
          eng:`What was your most important learning from the session?`,
          fr:`Qu’elle est la chose la plus importante que vous ayez appris pendant cette session`,
       },
       Q2:{
         eng:`Identify one thing you’re going to start doing?`,
         fr:`Identifiez une chose que vous allez commencer à faire`,
      },
      Q3:{
         eng:`Identify one thing you will continue doing?`,
         fr:`Identifiez une chose que vous allez continuer de faire`,
      },
      Q4:{
         eng:`Identify one thing you will stop doing?`,
         fr:`Identifiez une chose que vous allez arrêter de faire`,
      },
      RulesHeading:{
         eng:`Welcome to Equally Yours, a challenging and fun way to explore diversity and inclusion.   Here is a quick introduction to the rules.  `,
         fr: `Bienvenue à Equally Yours, un moyen innovant et amusant d’explorer les notions de diversité et d’inclusion.  Voici une explication rapide des règles.`
      },
      Rule1st:{
         eng:`You will be placed in a team with some of your colleagues.  Your team will have a maximum of 5 players.  Choose a team name and give this to the facilitator.   The objective is to travel around the board and accumulate as many points as possible.  The team with the most points at the end of the session are the winners.   You gain points by responding to questions and scenarios from 3 cards decks.  The card decks are`,
         fr:`Vous et vos collègues serez répartis en équipes de 5 joueurs maximum.  Choisissez un nom pour votre équipe et donnez-le à l’animateur. L’objectif est de faire le tour du plateau en accumulant le plus de points possibles. L’équipe qui a le plus de points à la fin de chaque session est déclarée gagnante.  Vous accumulez des points en répondant à des questions et des scénarios inclus dans 3 jeux de cartes. Les jeux de cartes sont `
      },Rule3rd:{
         eng:'To get the most out of this workshop, please be honest, curious and brave!',
         fr:'Pour profiter au maximum de cet atelier, soyez honnête, curieux et brave !'
      },
      Rule2nd:{
         eng:`When you are in charge of the board, you will have the first opportunity to respond to the question, but the other teams have the opportunity to also contribute and gain additional points.
         The facilitator will provide feedback on your answers and award points, including bonus points for the quality and depth of contributions.    
         `,
         fr:`Quand c’est votre tour, vous avez l’opportunité de répondre aux questions en premier, bien que les autres équipes puissent également contribuer et gagner des points.
         L’animateur vous fournira des commentaires sur vos réponses et attribuera des points, y compris des points bonus pour la qualité et la perspicacité des contributions. 
         `
      }, Rule1stLi:{
         eng:`Need to know are factually based questions, that have right answers `,
         fr:`Besoin de savoir sont des questions factuelles et qui ont des réponses justes `
      }, Rule2ndLi:{
         eng:`What would you do are scenario-based questions and you will explore how you will handle these scenarios.  `,
         fr:`Que feriez-vous sont des questions basées sur des scénarios et vous allez explorer comment vous répondriez à ces situations `
      },Rule3rdLi:{
         eng:`I dare you explore stereotypes and other issues that often are thought about but rarely discussed`,
         fr:`Je vous mets au défi explore les stéréotypes et autres problèmes auxquels nous pensons souvent mais dont on discute rarement`
      },
      Board:{
         eng:'High Contrast Board',
         fr:'Panneau à contraste élevé'
      },
      Points:{
         eng:'Points',
         fr:'Équipe'
      },
      partFeedBackForm:{
       eng:'Participant Feedback from',
       fr:'commentaires des participants de'
      },
      SubmitFeedback:{
         eng:'Submit Feedback',
         fr:'Soumettre les commentaires'
      },
      SubmitFeedbackText:{
         eng:'Are you sure you want to submit the feedback?',
         fr:`Êtes vous sûr que vous voulez soumettre les commentaires?`
      },
      sessionFeedBack:{
      eng:'Session FeedBack',
      fr:'Commentaires de session'
      },
      enterEmail:{
      eng:'Please Enter Email',
      fr:'Veuillez entrer votre adresse email'
      },
      thankyouFeedback:{
      eng:'Thankyou for your feedback',
      fr:'Merci pour vos commentaires'
      },
      sessionEnded:{
         eng:'This session has already ended',
         fr:'Cette session est déjà terminée'
      },
      roomFull:{
         eng:'Max capacity for the session has been reached, please contact the session facilitator',
         fr:'La capacité maximale de la session a été atteinte, veuillez contacter l’animateur de la session'
      },
      rollDice:{
         eng:'Dice roll',
         fr:'Roulette de dés'
      },
      rollTheDice:{
         eng:'Roll the dice',
         fr:'Lancer le dés'
      },
      refMaterial:{
         eng:'Reference Material',
         fr:'Matériau de référence'
      },
      yes:{
         eng:'yes',
         fr:'Oui'
      },
      team:{
         eng:"Team",
         fr:'Équipe'
      },
      No:{
         eng:'No',
         fr:'Non'
      },
      thankyou:{
         eng:'Thank you for participating in this session, Your feedback has been recorded!',
         fr:"Merci d'avoir participe acette session Vos commentaires ont ete enregistres! "
      },
       endGame:{
          eng:`End Game`,
          fr:`Mettre fin au jeu`
       },
       contentEndGame:{
          eng:`Are you sure you want to End the current session?`,
          fr:`Etes-vous sûr de vouloir mettre fin à la session en cours?`
       },
       endSession:{
          eng:`End Session`,
          fr:`End Session`
       },
       upTo:{
          eng:'Up to',
          fr:'Jusqu\'à'
       },
       Points_Available:{
          eng:'Points Available',
          fr:'Points disponibles'
       },
       QuestionNotAvl:{
         eng:'No Questions Available',
         fr:'Aucune question disponible'
       },
       Possible_Answer:{
          eng:'Possible Answer',
          fr:'Réponse possible'
       },
       Ending_the_Game:{
          eng:'Ending the Game',
          fr:'En train de mettre fin au jeu'
       },
       SAVE_FACILITATOR_NOTES:{
          eng:'SAVE FACILITATOR NOTES',
          fr:`Enregistrer les notes de l'animateur`
       },FACILITATOR_NOTES:{
          eng:'FACILITATOR NOTES',
          fr:`Notes de l'animateur`
       },
       Confirmation:{
          eng:"Confirmation",
          fr:"Confirmation"
       },Notes_Editable:{
          eng:'These Notes are not editable.',
          fr:'Ces notes ne sont pas modifiables'
       },Notes_Message:{
          eng:`Are you sure you want to save these notes with this session?`,
          fr:`Êtes-vous sûr de vouloir enregistrer ces notes avec cette session ?`
       },Roll_Again:{
          eng:'Roll Again',
          fr:'Relancer les dés'
       },
       Taking_Turn:{
          eng:'Taking Turn',
          fr:'A tour de rôle'
       },
       Pick_Card:{
          eng:'Pick a card',
          fr:'Choisir une carte',
       },Skip_Turn:{
       eng:'Skip Turn',
       fr:'Passer son tour'
       },
       overRide:{
          eng:'Override',
          fr:'Passer outre'
       },Assign_Points:{
          eng:'Assign Points',
          fr:"Attribuer des points"
       },
       Next_Turn:{
          eng:'Next Turn',
          fr:'Tour suivant'
       },Text:{
          eng:'Text',
          fr:'Texte'
       },
       Video:{
         eng:'Video',
         fr:'Vidéos'
       },
       Image:{
          eng:'Images',
          fr:'Images'
       },Reference_Text_Not_Available:{
          eng:'Reference Text Not Available',
          fr:'Texte de référence non disponible'
       },
       Reference_Video_Not_Available:{
          eng:'Reference Video Not Available',
          fr:'Vidéo de référence non disponible'
       },
       Reference_Images_Not_Available:{
          eng:"Reference Images Not Available",
          fr:'Images de référence non disponibles'
       },
       welcome:{
          eng:'Welcome, ',
          fr:'Bienvenue, '
       },
       sessionWith:{
         eng:'Session with ',
         fr:'Session avec '
       },
       gameOver:{
          eng:'This session has ended',
          fr:'Cette session est déjà terminée'
       },
       cleint:{
          eng:'Client',
          fr:''
       },
       Created_On:{
               eng:'Created On',
               fr:'Crée le'
       },
       facilitatorJoin:{
          eng:'Facilitator has connected to the game',
          fr:`L'animateur s'est connecté au jeu`
       },
       facilitatorDisconnect:{
         eng:'Facilitator has disconnected from the game',
         fr:`L'animateur s'est déconnecté au jeu`
      },
      youRemoved:{
         eng:`You have been removed from the game`,
         fr:`Vous avez été exclu du jeu`
      },
      ParticipantJoinedDesc:{
         eng:`has joined the game`,
         fr:`a rejoint le jeu`
      },ParticipantJoined:{
         eng:`Participant Joined`,
         fr:`Participant ajouté`
      }, ParticipantRemoved:{
         eng:`Participant Removed`,
         fr:`Participant exclu`
      },ParticipantLeft:{
         eng:` has left the game`,
         fr:` a quitté le jeu`
      },
      Participant:{
         eng:'Participant',
         fr:'Participant'
      },
      facilitator:{
         eng:'Facilitator',
         fr:`L'animateur`
      },
      save:{
         eng:'Save',
         fr:'Enregistrer'
      }
      ,Confirm:{
         eng:'Confirm',
         fr:'Confirmer'
      },poweredBy:{
         eng:'Powered By ',
         fr:'Propulsé par'
      },
      CopyRight:{
         eng:"Copyright",
         fr:'Droits d auteur'
      },
      Platform:{
         eng:'Platform built by ',
         fr:'Plate-forme construite par '
      }
   
 }


