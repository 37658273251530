import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component }  from 'react';
import logo from './logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import 'react-notifications/lib/notifications.css';
import Routes from './Routes';



export default class App extends Component {
    render() {
        return (
            <div>
                <Routes/>
            </div>
        )
    }
}
