import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./ducks/Reducers";
const initialState = {};
const middleware = [thunk];
const store = createStore(
  rootReducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
 //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    //    window.REDUX_DEVTOOLS_EXTENSION ?
    //       window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);
export default store;
