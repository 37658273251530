import { LANG } from "../actions/actionsTypes";

const initialState = {
  rLang: "eng",
};

const langReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case LANG:
      return {
        ...state,
        rLang: actions.payload,
      };
   

    default:
      return state;
  }
};

export default langReducer;
